import "../public/css/bloom.min.css";
import "../public/js/bloom"

import {lazy, Suspense} from "react";
import {Route, Routes} from "react-router-dom";

const Overlay = lazy(() => import("../components/Overlay"));
const HeaderMenu = lazy(() => import("../components/HeaderMenu"));
const FooterMenu = lazy(() => import("../components/FooterMenu"));
const MainScreen = lazy(() => import("./MainScreen"));
const TransportRules = lazy(() => import("./TransportRules"));

export function App() {
    return (
        <Suspense fallback={<div></div>}>
            <Overlay/>
            <HeaderMenu/>
            <main>
                <Routes>
                    <Route path='/' element={<MainScreen/>}/>
                    {/*<Route path='/en' element={<Navigate to='/'/>}/>*/}
                    <Route path='rules' element={<TransportRules/>}/>
                </Routes>
            </main>
            <FooterMenu/>
        </Suspense>
    );
}